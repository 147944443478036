

import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Pagination from "@/components/base/paging/Pagination.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ACTIVITY_TYPE} from "@/core/config/Constant";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue";
import ActivityIcon from "@/views/activity/ActivityIcon.vue";
import Area from "@/components/base/Area.vue";

export default defineComponent({
  name: "CompletedActivities",
  components: {Area, ActivityIcon, BaseCheckBox, EntityLoading, BaseSelect, Avatar, DateTimeFormat, Pagination},
  props: {
    type: {type: String, required: true},
    id: {type: String, required: true},
    area:{type:String, default: ()=>'GENERIC'},
  },
  emits: ['showDetail'],
  setup(props, {emit}) {
    const refTpe = ref(props.type)
    const refId = ref(props.id)
    const filterObject = ref({
      cazeId: '',
      claimId: '',
      processId :  '',
      invoiceId: '',
      status: 'DONE',
      policyId: '',
      type: '',
      includeCase: false,
      logReportId: '',
      area: props.area
    })
    switch (refTpe.value) {
      case 'CASE':
        filterObject.value.cazeId = refId.value
        break
      case 'CLAIM':
        filterObject.value.claimId = refId.value
        break
      case 'INVOICE':
        filterObject.value.invoiceId = refId.value
        break
      case 'POLICY' :
        filterObject.value.policyId = refId.value
        break
      case 'PROCESS':
        filterObject.value.processId = refId.value
        break
      case 'LOG_REPORT':
        filterObject.value.logReportId = refId.value
        break
    }
    const completePage = computed(() => store.state.ActivityModule.completePage)
    const showDetail = (id) => {
      emit('showDetail', id);
    }
    return {
      ...LoadFilterObjects(Actions.LOAD_COMPLETE_ACTIVITIES, filterObject.value, ['user']),
      completePage,
      showDetail,
      ACTIVITY_TYPE,
      filterObject,
    }
  },
  methods: {
    onFilter(type) {
      this.filterObject.type = type;
      this.updateFilterObject(this.filterObject);
    },
    onUpdateFilter() {
      this.updateFilterObject(this.filterObject)
    }
  }
})
