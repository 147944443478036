
import { defineComponent, ref } from "vue";
import ActivityService from "@/core/services/ActivityService";
import { ACTIVITY_TYPE } from "@/core/config/Constant";

export default defineComponent({
  name: "ActivitySummary",
  props: {
    id: {type: String},
    type: {type: String},
  },
  setup(props) {
    const summaries = ref([])
    const fetchData = async (id, type) => {
      await ActivityService.summary<any>(id, type).then(res => {
        summaries.value = res
      })
    }

    const loadData = () => {
      fetchData(props.id, props.type)
    }
    loadData()
    return {
      loadData,
      summaries,
      ACTIVITY_TYPE
    }
  }
})
